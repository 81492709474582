<template>
  <b-container class="report-account-plan-2 report-account-plan-2-auto-import bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_liabilities') }}</h1>
      </b-row>
      <account-plan-submenu active="liabilities"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-liabilities-header></account-plan-liabilities-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs"  @activate-tab="onTabActivate">
            <LiabilityMenu>
              <template slot="account-plan-liabilities-tink">
                <b-row no-gutters>
                  <b-col cols="8">
                    <div class="text-regular" v-html="$t(translationPath + 'liability.tink.description')"></div>
                  </b-col>
                </b-row>
                <b-table class="spirecta-simple-table account-plan-table income-accounts-table" show-empty hover
                         responsive striped
                         stacked="md"
                         :items="accountsGrouped.liability"
                         :fields="fieldValues"
                         :tbodyTrClass="trClass"
                         :busy="busy"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <template v-slot:cell(title)="row">
                    <template v-if="row.item && row.item.is_account === true">
                      <span class="px-2 account_title">&ndash;</span>
                      <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view'" class="account_title">{{
                        row.value }}
                      </b-link>
                      <b-badge v-if="row.item.status === 'suspended'" variant="warning" class="ml-2 cursor-help"
                               :title="$t(translationPath+'badge_suspended_help')">{{ $t( translationPath +
                        'badge_suspended') }}
                      </b-badge>
                    </template>
                    <template v-else-if="row.item">
                      <b-link :to="'/reports/performance/account-groups/'+row.item.id" class="account_group_title">{{
                        row.value }}
                      </b-link>
                    </template>
                    <template v-else>&nbsp;</template>
                  </template>

                  <template v-slot:cell(input_tink_provider)="row">
                    <template v-if="row.item.is_account">
                      <b-form-select
                        :options="providerOptions"
                        v-model="row.item.tink_input_provider"
                        @input="hasUnsavedChanges = true"
                        @change="row.item.tink_id = null"
                        :state="isSubmitted && row.item.tink_auto_import ? (row.item.tink_input_provider ? true : false) : null"
                      ></b-form-select>
                    </template>
                    <template v-else>
                      <div class="tr_heading">{{ $t(translationPath + 'input_tink_provider') }}</div>
                    </template>
                  </template>

                  <template v-slot:cell(tink_id)="row">
                    <template v-if="row.item.is_account">
                      <b-form-select
                        :options="filterTinkAccountOptions(row.item.tink_input_provider)"
                        :state="isSubmitted && row.item.tink_auto_import ? (row.item.tink_id ? true : false) : null"
                        v-model="row.item.tink_id"
                        @input="hasUnsavedChanges = true"
                      ></b-form-select>
                    </template>
                    <template v-else>
                      <div class="tr_heading">{{ $t(translationPath + 'tink_account_id') }}</div>
                    </template>
                  </template>

                  <template v-slot:cell(tink_id_actions)="row">
                    <template v-if="row.item.is_account">
                      <b-link
                        v-if="row.item.tink_input_provider"
                        class='btn plain-btn text-regular mr-md-3 action-button float-left float-md-right btn-update-accounts-list'
                        @click.stop="openTinkLinkModal(row.item.tink_input_provider)"
                      >
                        <i class="flaticon solid text-secondary refresh"></i>
                      </b-link>
                    </template>
                  </template>

                  <template v-slot:cell(tink_auto_import)="row">
                    <template v-if="row.item.is_account">
                      <b-form-select
                        class="float-left float-md-right"
                        v-model="row.item.tink_auto_import"
                        :options="autoImportOptions"
                      ></b-form-select>
                    </template>
                    <template v-else>
                      <div class="tr_heading">{{ $t(translationPath + 'auto_import') }}</div>
                    </template>
                  </template>
                </b-table>

                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right ml-3 px-4 btn-save"
                      :disabled="saveDisabled || busy"
                      @click="onSave"
                    >{{ $t('common.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </LiabilityMenu>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>

    <tink-update-accounts-modal
      ref="TinkModal"
      @connected="updateTinkAccountsList"
    />
  </b-container>
</template>

<script>
import AccountPlanTabMixin from './AccountPlanTabMixin'
import { mapState } from 'vuex'
import axios from 'axios'
import TinkUpdateAccountsModal from '@/components/modals/TinkUpdateAccountsModal'
import LiabilityMenu from './submenu/LiabilityMenu'
import AccountPlanLiabilitiesHeader from './headers/AccountPlanLiabilitiesHeader'

export default {
  name: 'AccountPlanAssetsTink',
  mixins: [AccountPlanTabMixin],
  components: { TinkUpdateAccountsModal, LiabilityMenu, AccountPlanLiabilitiesHeader },
  data () {
    return {
      tinkProviders: [],
      isSubmitted: false
    }
  },
  computed: {
    ...mapState('user', ['currentCOA', 'currentUser']),
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title') },
        { key: 'input_tink_provider', label: this.$t(this.translationPath + 'input_tink_provider') },
        { key: 'tink_id', label: this.$t(this.translationPath + 'bank_account') },
        { key: 'tink_id_actions', class: 'td-tink-id-actions', label: this.$t(this.translationPath + 'bank_account_actions') },
        { key: 'tink_auto_import', label: this.$t(this.translationPath + 'auto_import'), class: 'td-auto-import' }
      ]
    },
    providerOptions () {
      let opts = []
      this.tinkProviders.map(item => {
        opts.push({ text: this.$t(item.translation_string_id), value: item.provider_name })
      })

      opts = opts.sort((a, b) => a.text < b.text ? -1 : 1)
      opts.unshift({ text: this.$t('common.not_selected'), value: null })
      return opts
    },
    tinkAccountOptions () {
      const opts = []
      this.tinkProviders.map(provider => {
        provider.tink_accounts.map(account => {
          opts.push({
            text: (account.connection ? account.connection.name : this.$t(this.translationPath + 'no_connection_prefix')) + ' - ' + account.name + ' - ' + account.number,
            value: account.tink_account_id,
            provider: provider.provider_name
          })
        })
      })
      return opts.sort((a, b) => a.text < b.text ? -1 : 1)
    },
    autoImportOptions () {
      return [
        { value: 0, text: this.$t('common.off') },
        { value: 1, text: this.$t('common.on') }
      ]
    }
  },
  methods: {
    validate () {
      let isValid = true
      this.accountsGrouped.liability.map(account => {
        if (account.is_account && account.tink_auto_import && (!account.tink_input_provider || !account.tink_id)) {
          isValid = false
        }
      })
      return isValid
    },
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }
      this.busy = true

      Promise.all([
        this.loadAccountGroupsWithData('liability'),
        axios.get(`${process.env.VUE_APP_ROOT_API}/tink/providers?is_test=${Number(this.currentUser.tink_app !== 'production')}`)
          .then((response) => {
            this.tinkProviders = response.data.data
          })
      ])
        .then(() => {
          this.busy = false
        })
    },
    onSave () {
      this.isSubmitted = true
      if (!this.validate()) {
        this.$bvToast.toast(this.$t(this.translationPath + 'alert_invalid_fields_description').toString(), {
          title: this.$t(this.translationPath + 'alert_update_tink_title').toString(),
          variant: 'danger',
          solid: true,
          'auto-hide-delay': 3000
        })
        return false
      }

      this.saveDisabled = true
      const putData = {
        accounts: []
      }

      this.accountsGrouped.liability.map(item => {
        if (item.is_account) {
          putData.accounts.push({
            id: item.id,
            tink_input_provider: item.tink_input_provider,
            tink_id: item.tink_id,
            tink_auto_import: item.tink_auto_import
          })
        }
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then((response) => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_liability_providers_success_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_tink_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.hasUnsavedChanges = false
          this.isSubmitted = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_liability_providers_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_tink_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .then(() => {
          this.saveDisabled = false
        })
    },
    filterTinkAccountOptions (providerName) {
      const opts = [
        { text: this.$t('common.not_selected'), value: null },
        ...this.tinkAccountOptions.filter(item => item.provider === providerName)
      ]
      return opts
    },
    openTinkLinkModal (providerName) {
      this.$refs.TinkModal.$show(providerName)
    },
    updateTinkAccountsList (eventData) {
      this.$refs.TinkModal.$hide()
      for (const i in this.tinkProviders) {
        if (this.tinkProviders[i].id === eventData.provider.id) {
          this.tinkProviders[i].tink_accounts = eventData.db_accounts
          break
        }
      }
      this.$bvToast.toast(this.$t(this.translationPath + 'tink_modal.alert.success.accounts_updated').toString(), {
        title: this.$t(this.translationPath + 'tink_modal.title').toString(),
        variant: 'success',
        solid: true,
        'auto-hide-delay': 3000
      })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    tinkProviders: {
      deep: true,
      handler () {
        this.tinkProviders.map(provider => {
          provider.tink_accounts.map(account => {
            const tmp = account.connection.name.match(/#.+#/)
            if (tmp !== null) {
              account.connection.name = account.connection.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
            }
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import './AccountPlan.scss';
</style>
