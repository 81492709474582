<template>
  <b-modal ref="Modal">
    <div slot="modal-header" class="w-100 mx-auto text-center">
      <button type="button" class="close" aria-label="Close" :disabled="!errorMessage && !isIframeShown" @click="$hide">
        <span aria-hidden="true">×</span>
      </button>
      <h2>{{ $t(translationPath + 'title') }}</h2>
    </div>

    <div slot="default" class="tink-update-accounts-modal-body">
      <loader v-if="!errorMessage && !successMessage && !isIframeShown" :loading-text="loaderText" />
      <b-alert :show="Boolean(errorMessage)" variant="danger">{{ errorMessage }}</b-alert>
      <b-alert :show="Boolean(successMessage)" variant="success">{{ successMessage }}</b-alert>

      <b-embed
        v-if="isIframeShown"
        ref="TinkIframe"
        id="bank_iframe"
        class="mt-5 tink-iframe"
        type="iframe"
        :src="tinkLink"
        allow-scripts
        allow-same-origin
        allow-popups
        allow-forms
        allow-popups-to-escape-sandbox
        allow-top-navigation
      />
    </div>

    <div slot="modal-footer" class="tink-update-accounts-modal-footer">
      <b-button class="btn-sm btn-primary" variant="danger" :disabled="!errorMessage && !successMessage && !isIframeShown" @click="$hide">{{ $t('common.close') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import Tink from '@/mixins/Tink'

export default {
  name: 'TinkUpdateAccountsModal',
  components: { Loader },
  mixins: [Tink],
  data () {
    return {
      translationPath: 'reports.other.account_plan.tink_modal.',
      loaderText: '',
      providerName: null,
      connection: null,
      errorMessage: null,
      successMessage: null,
      isIframeShown: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    locale () {
      let val = 'en_US'
      switch (this.currentUser.default_lang) {
        case 'sv':
          val = 'sv_SE'
          break
        case 'da':
          val = 'da_DK'
          break
        case 'en':
        default:
          val = 'en_US'
      }

      return val
    },
    tinkLink () {
      if (!this.connection) {
        return ''
      }
      if (!this.connection.tink_credentials_authorization_code) {
        return ''
      }

      const link = `https://link.tink.com/1.0/credentials/add?client_id=${this.currentUser.tink_client_id}&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read&market=SE&locale=${this.locale}&iframe=true&authorization_code=${this.connection.tink_credentials_authorization_code}&input_provider=${this.providerName}&redirect_uri=${document.location.origin}/tink-callback`

      return link
    }
  },
  methods: {
    $hide () {
      this.$refs.Modal.hide()
      this.errorMessage = null
      this.successMessage = null
      this.providerName = null
      this.connection = null
      this.isIframeShown = false
    },
    $show (providerName) {
      this.providerName = providerName
      this.$refs.Modal.show()
      this.errorMessage = null
      this.successMessage = null
      this.getConnection()
        .then(() => {
          if (!this.connection.credentials_id) {
            this.isIframeShown = true
          } else {
            this.updateAccounts()
          }
        })
    },
    async getConnection () {
      return new Promise((resolve, reject) => {
        this.loaderText = this.$t(this.translationPath + 'loader_text.connecting')
        axios.post(`${process.env.VUE_APP_ROOT_API}/tink/last-connection/${this.providerName}`)
          .then(response => {
            this.connection = response.data.data
            resolve(this.connection)
          })
          .catch(err => {
            this.errorMessage = err.response.status + ' ' + err.response.statusText
            console.error(err)
            resolve(err)
          })
      })
    },
    receiveIframeMessage (event) {
      if (event.origin !== 'https://link.tink.com') {
        return false
      }

      const { type, error, additionalInformation } = JSON.parse(event.data)
      if (type !== 'error' && type !== 'none') {
        return false
      }

      this.isIframeShown = false
      this.loaderText = this.$t(this.translationPath + 'loader_text.saving_credentials')
      if (error) {
        this.errorMessage = error.message
        return false
      }

      const credentialsId = Object.prototype.hasOwnProperty.call(additionalInformation, 'credentialsId') ? additionalInformation.credentialsId : null
      if (type === 'none' && credentialsId) {
        this.saveCredentials(credentialsId, this.connection.id)
          .then(() => {
            this.updateAccounts()
          })
          .catch(err => {
            console.error(err)
          })
        return true
      }

      return false
    },
    async updateAccounts () {
      return new Promise((resolve, reject) => {
        this.loaderText = this.$t(this.translationPath + 'loader_text.updating_accounts')

        axios.post(`${process.env.VUE_APP_ROOT_API}/tink/update-tink-accounts/${this.providerName}`)
          .then(response => {
            this.successMessage = this.$t(this.translationPath + 'alert.success.accounts_updated')
            this.$emit('connected', response.data.data)
            resolve(response)
          })
          .catch(err => {
            this.errorMessage = err.response.status + ' ' + err.response.statusText
            console.error(err)
            reject(err)
          })
      })
    }
  },
  mounted () {
    this.$listenEvents()
  },
  beforeDestroy () {
    this.$removeEvents()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/tink-update-accounts-modal.scss'
</style>
